
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import LightBox from "vue-image-lightbox";
import "swiper/css/swiper.css";

export default {
  components: {
    // ImgCard: () => import("@/components/ImgCard"),
    // Paragraph: () => import("@/components/Paragraph"),
    // Parallex: () => import("@/components/Parallex"),
    // CardInfomationStack: () => import("@/components/CardInfomationStack"),
    // CardInfomationFull: () => import("@/components/CardInfomationFull"),
    Swiper,
    SwiperSlide,
    LightBox,
  },
  data() {
    return {
      video: null,
      card: null,
      morningImg: {
        title: null,
        info: null,
        img: null,
      },
      nightImg: {
        title: null,
        info: null,
        img: null,
      },
      reviewImg: {
        title: null,
        info: null,
        img: null,
      },
      lang: this.$router.currentRoute.params.lang,
      root: "wedding",
      fullImg: false,
      media: [],
      swiperOption: {
        // effect: "coverflow",
        // grabCursor: true,
        // centeredSlides: true,
        // slidesPerView: "auto",
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        // coverflowEffect: {
        //   rotate: 50,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows: true,
        // },
        // pagination: {
        //   el: ".swiper-pagination",
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  metaInfo () {
    return {
      title: 'Wedding',
      titleTemplate: '%s | Kaewsamui resort',
      meta: [
        { name: 'description', content: 'Warm hotel, comfortable like at home. Located in Muang Suratthani Surrounded by the natural atmosphere of Khao Tha Phet' },
        { property: 'og:title', content: 'Wedding' },
        { property: 'og:site_name', content: 'Kaewsamui resort' },
        { property: 'og:type', content: 'website' },    
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  async mounted() {
    await this.getAccommodation();
  },
  methods: {
    async getAccommodation() {
      const { results } = await this.$prismic.client.query([
        this.$prismic.Predicates.at("document.type", "wedding"),
        this.$prismic.Predicates.at("document.tags", [this.lang]),
      ]);
      console.log(results);
      this.video = results[0].data.video.url;
      this.morningImg.title = results[0].data.morning_title[0].text;
      this.morningImg.info = results[0].data.morning_info[0].text;
      this.morningImg.img = results[0].data.morning_list;

      this.nightImg.title = results[0].data.night_title[0].text;
      this.nightImg.info = results[0].data.night_info[0].text;
      this.nightImg.img = results[0].data.night_list;

      this.reviewImg.title = results[0].data.review_title[0].text;
      this.reviewImg.info = results[0].data.review_info[0].text;
      this.reviewImg.img = results[0].data.review_list;
    },
    handleClickMorningSlide(index, reallyIndex) {
      this.GetDataDetail(this.morningImg.img);
      this.$refs.lightbox.showImage(reallyIndex);
    },
    handleClickNightSlide(index, reallyIndex) {
      this.GetDataDetail(this.nightImg.img);
      this.$refs.lightbox.showImage(reallyIndex);
    },
    handleClickReviewSlide(index, reallyIndex) {
      this.GetDataDetail(this.reviewImg.img);
      this.$refs.lightbox.showImage(reallyIndex);
    },
    GetDataDetail(list) {
      this.media = [];
      list.forEach((arr) => {
        this.media.push({
          thumb: arr.img.url,
          src: arr.img.url,
          caption: "caption to display. receive <html> <b>tag</b>",
        });
      });
    },
  },
  watch: {
    $route() {
      this.lang = this.$router.currentRoute.params.lang;
      this.getAccommodation();
    },
  },
};
